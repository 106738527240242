<template>
  <v-card elevation="24">
    <v-card-text>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined color="primary" @click="focus = ''">{{ $t('today') }}</v-btn>
          <v-btn fab text small color="secondary" @click="$refs.calendar.prev()">
            <v-icon small>{{ mdiChevronLeft }}</v-icon>
          </v-btn>
          <v-btn fab text small color="secondary" @click="$refs.calendar.next()">
            <v-icon small>{{ mdiChevronRight }}</v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar" class="text-capitalize">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer/>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="secondary" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>{{ mdiMenuDown }}</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>{{ typeToLabel.day }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>{{ typeToLabel.week }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>{{ typeToLabel.month }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="75vh">
        <v-calendar
          ref="calendar"
          :locale="locale"
          v-model="focus"
          color="primary"
          :events="events"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
          :first-interval="7"
          :interval-count="13"
          weekdays="1, 2, 3, 4, 5, 6, 0"
        >
        </v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card>
            <v-toolbar :color="selectedEvent.color">
              <v-toolbar-title class="white--text">
                <div>{{ selectedEvent.name }}</div>
                <div>
                  {{ $t('fields.technician') }}: {{ technician }}
                  <v-icon color="white">{{ mdiCalendar }}</v-icon> {{ date }}
                  <v-icon color="white">{{ mdiClockOutline }}</v-icon>
                  {{ startHour }} - {{ endHour }}
                </div>
              </v-toolbar-title>
              <v-menu right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon right color="white">{{ mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    :to="{ name: 'patInfo', params: { pastamp: this.pastamp } }"
                    target="_blank"
                  >
                    {{ $t('openPAT') }}
                  </v-list-item>
                  <v-list-item
                    :to="{
                      name: 'editIntervention',
                      params: { pastamp: this.pastamp, mhstamp: this.mhstamp }
                    }"
                    target="_blank"
                  >
                    {{ $t('editIntervention') }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
            <v-card-text>
              {{ report }}
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn text @click="selectedOpen = false">{{ $t('aClose') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiMenuDown,
  mdiCalendar,
  mdiClockOutline,
  mdiDotsVertical,
} from '@mdi/js';
import dayjs from '@/plugins/dayjs';
import helpers from '@/resources/helpers';

export default {
  metaInfo() {
    return {
      title: this.$t('homePage'),
    };
  },
  mixins: [helpers],
  data() {
    return {
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      mdiChevronLeft,
      mdiChevronRight,
      mdiMenuDown,
      mdiCalendar,
      mdiClockOutline,
      mdiDotsVertical,
      locale: process.env.VUE_APP_I18N_LOCALE,
      focus: '',
      type: 'week',
      typeToLabel: {
        month: this.$t('month'),
        week: this.$t('week'),
        day: this.$t('day'),
      },
      events: [],
    };
  },
  computed: {
    ...mapFields('support', ['interventions']),
    pastamp() {
      return this.selectedEvent.intervention?.pa.pastamp;
    },
    mhstamp() {
      return this.selectedEvent.intervention?.mhstamp;
    },
    technician() {
      console.log(this.selectedEvent);
      return this.selectedEvent.intervention?.tecnnm;
    },
    date() {
      return this.formatDate(this.selectedEvent.intervention?.data);
    },
    startHour() {
      return this.selectedEvent.intervention?.hora;
    },
    endHour() {
      return this.selectedEvent.intervention?.horaf;
    },
    report() {
      return this.selectedEvent.intervention?.relatorio;
    },
  },
  methods: {
    ...mapActions('support', ['fetchInterventions']),
    viewDay({ date }) {
      this.focus = date;
      this.type = 'day';
    },
    async updateRange({ start, end }) {
      await this.fetchInterventions({ startDate: start.date, endDate: end.date });

      this.events = this.interventions.map((intervention) => ({
        name: `#${intervention.mhid} - ${intervention.pa.resumo}, ${intervention.nome} (${this.$t('shortNumber')} ${intervention.no})`,
        start: dayjs(`${intervention.data} ${intervention.hora}`, 'YYYY-MM-DD HH:mm').toDate(),
        end: dayjs(`${intervention.data} ${intervention.horaf}`, 'YYYY-MM-DD HH:mm').toDate(),
        color: intervention.realizada ? 'success darken-3' : 'error darken-3',
        timed: true,
        intervention,
      }));
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;

        requestAnimationFrame(() => requestAnimationFrame(() => {
          this.selectedOpen = true;
        }));
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;

        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    mounted() {
      this.$refs.calendar.checkChange();
    },
  },
};
</script>
