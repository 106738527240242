var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"24"}},[_c('v-card-text',[_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){_vm.focus = ''}}},[_vm._v(_vm._s(_vm.$t('today')))]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.$refs.calendar.prev()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiChevronLeft))])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiChevronRight))])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"secondary"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(_vm._s(_vm.mdiMenuDown))])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.typeToLabel.day))])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.typeToLabel.week))])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.typeToLabel.month))])],1)],1)],1)],1)],1),_c('v-sheet',{attrs:{"height":"75vh"}},[_c('v-calendar',{ref:"calendar",attrs:{"locale":_vm.locale,"color":"primary","events":_vm.events,"type":_vm.type,"first-interval":7,"interval-count":13,"weekdays":"1, 2, 3, 4, 5, 6, 0"},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"change":_vm.updateRange},model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":_vm.selectedEvent.color}},[_c('v-toolbar-title',{staticClass:"white--text"},[_c('div',[_vm._v(_vm._s(_vm.selectedEvent.name))]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('fields.technician'))+": "+_vm._s(_vm.technician)+" "),_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.mdiCalendar))]),_vm._v(" "+_vm._s(_vm.date)+" "),_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.mdiClockOutline))]),_vm._v(" "+_vm._s(_vm.startHour)+" - "+_vm._s(_vm.endHour)+" ")],1)]),_c('v-menu',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"right":"","color":"white"}},[_vm._v(_vm._s(_vm.mdiDotsVertical))])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'patInfo', params: { pastamp: this.pastamp } },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('openPAT'))+" ")]),_c('v-list-item',{attrs:{"to":{
                    name: 'editIntervention',
                    params: { pastamp: this.pastamp, mhstamp: this.mhstamp }
                  },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('editIntervention'))+" ")])],1)],1)],1),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.report)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.selectedOpen = false}}},[_vm._v(_vm._s(_vm.$t('aClose')))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }